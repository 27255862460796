input[type='checkbox'] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px !important;
  height: 16px !important;
  appearance: none;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  padding-bottom: 3px;
  background-color: #f2f2f2 !important;
  cursor: pointer;
}

input[type='checkbox']:checked {
  background-color: #f2f2f2 !important;
  border: none !important;
}

input[type='checkbox']:checked:after {
  content: url('assets/icons/black_tick.svg');
  color: black;
}
