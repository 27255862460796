.rc-slider-horizontal {
  border-radius: 0 !important;
}

.rc-slider-rail {
  border-radius: 0 !important;
  background-color: #15151533 !important;
}

.rc-slider-track {
  border-radius: 0 !important;
  background-color: #4db96b !important;
  opacity: 50% !important;
}

.rc-slider-handle {
  width: 16px !important;
  height: 16px !important;
  border: none !important;
  background-color: #4db96b !important;
  opacity: 1 !important;
  margin-top: -6px !important;
}

.rc-slider-handle-dragging {
  box-shadow: none !important;
}
